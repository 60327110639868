import React from 'react';
import { Button, Container, Row, Col } from 'reactstrap';

import { useResourceQuery } from '@providers/ResourceQuery/ResourceQuery';
import ResourceTypeFilter from '@components/ResourceTypeFilter/ResourceTypeFilter';
import ResourceSearchFilter from '@components/ResourceSearchFilter/ResourceSearchFilter';
import ResourceCard from '@components/ResourceCard/ResourceCard';
import Disclaimer from '@components/Disclaimer/Disclaimer';
import NoResources from '@components/NoResources/NoResources';

const ResourceList = ({ xs, md, lg, xl, className = '' }) => {
  const { showing, fetchNext, hasMore } = useResourceQuery();

  const rowClasses = [className];
  if (xs) {
    rowClasses.push(`row-cols-${xs}`);
  }
  if (md) {
    rowClasses.push(`row-cols-md-${md}`);
  }
  if (lg) {
    rowClasses.push(`row-cols-lg-${lg}`);
  }
  if (xl) {
    rowClasses.push(`row-cols-xl-${xl}`);
  }

  return (
    <section className="section pt-5 pb-1">
      <Container>
        <Row className="justify-content-center mb-3">
          <Col lg="6" className="text-center">
            <ResourceSearchFilter />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="text-center">
            <ResourceTypeFilter />
          </Col>
        </Row>

        {showing.length === 0 && <NoResources />}
        {showing.length > 0 && (
          <>
            <Row className={`${rowClasses.join(' ')}`}>
              {showing.map((resource) => (
                <ResourceCard key={resource.uuid} resource={resource} />
              ))}
            </Row>
            <div className="mt-4 text-center">
              {hasMore && (
                <Button
                  color="primary"
                  onClick={fetchNext}
                  className="shadow-soft border-soft mr-2 mb-2 animated-grow-1"
                >
                  Load more
                </Button>
              )}
            </div>
            <Disclaimer />
          </>
        )}
      </Container>
    </section>
  );
};

export default ResourceList;
