import React from 'react';
import { Button } from 'reactstrap';
import { useStaticQuery, graphql } from 'gatsby';
import { useMedia } from 'react-use';
import { FormGroup, Input, Label } from 'reactstrap';

import { useResourceQuery } from '@providers/ResourceQuery/ResourceQuery';

const ResourceTypeFilter = () => {
  const { query, updateQuery } = useResourceQuery();
  const {
    allCategories: { nodes: categories },
  } = useStaticQuery(graphql`
    query CategoriesQuery {
      allCategories: allCategory {
        nodes {
          uuid
          emojiicon
          name
          slug
        }
      }
    }
  `);
  const isLarge = useMedia('(min-width: 992px)');
  // const isMedium = useMedia('(min-width: 768px)');

  if (isLarge) {
    return (
      <ul className="list-inline filter-group">
        {categories.map(({ uuid, slug, name, emojiicon }) => {
          return (
            <li key={uuid} className="list-inline-item">
              <Button
                className={`filter-pill rounded-pill border-0  mr-2 py-1 px-2 animated-grow-1 ${
                  query.category === slug
                    ? 'bg-primary text-white'
                    : 'bg-transparent text-dark-gray'
                }`}
                color="dark"
                onClick={() => {
                  updateQuery('category', slug);
                }}
              >
                {emojiicon} {name}
              </Button>
            </li>
          );
        })}
      </ul>
    );
  } else {
    return (
      <FormGroup className="mb-lg-0">
        <Label className="sr-only" htmlFor="category">
          Category
        </Label>
        <Input
          className="text-dark-gray"
          type="select"
          id="category"
          name="category"
          value={query.category}
          onChange={(ev) => {
            updateQuery('category', ev.target.value);
          }}
        >
          {categories.map(({ uuid, slug, name, emojiicon }) => (
            <option key={uuid} value={slug}>
              {emojiicon} {name}
            </option>
          ))}
        </Input>
      </FormGroup>
    );
  }
};

export default ResourceTypeFilter;
