import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import useContent from '@hooks/useContent/useContent';

const Disclaimer = () => {
  const { getContent } = useContent();
  return (
    <div className="mt-4 text-center">
      <Container>
        <Row>
          <Col className="mx-auto text-center">
            <p className="text-dark-gray">
              <small>
                <span className="font-weight-bolder">Disclaimer:</span>{' '}
                {getContent('affiliate-disclaimer')}
              </small>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Disclaimer;
