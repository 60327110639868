import React, { createContext, useState, useContext, useEffect } from 'react';
import { useList, useSetState } from 'react-use';
import { navigate } from 'gatsby';
import makeDebug from 'debug';

const debug = makeDebug('providers/ResourceQuery');

const defaultQuery = {
  category: 'latest',
  search: '',
};

export const ResourceQueryContext = createContext({
  query: defaultQuery,
  list: [],
  showing: [],
  updateQuery: () => {},
  fetchNext: () => {},
});

export const useResourceQuery = () => useContext(ResourceQueryContext);

const ResourceQueryProvider = ({
  initialQuery = {},
  resources = [],
  initialSize = 25,
  pageSize = 25,
  children,
}) => {
  const [query, setQuery] = useSetState({ ...defaultQuery, ...initialQuery });
  const [list, { filter: filterList, reset: resetFilter }] = useList(resources);
  const [showing, { set: setShowing, push: pushShowing }] = useList(
    list.slice(0, initialSize)
  );
  useEffect(() => {
    debug(`Query updated to category=${query.category}`);

    if (query.category === 'latest') {
      navigate('/');
    } else {
      navigate(`/category/${query.category}`);
    }
  }, [query.category]);
  useEffect(() => {
    debug(`Query updated to search=${query.search}`);
    resetFilter();
    const searchRe = new RegExp(query.search, 'ig');
    filterList(
      (item) => searchRe.test(item.title) || searchRe.test(item.description)
    );
  }, [filterList, resetFilter, query.search]);

  useEffect(() => {
    debug(`List updated to length=${list.length}`);
    setShowing(list.slice(0, initialSize));
  }, [setShowing, initialSize, list]);

  const updateQuery = (key, value) => {
    setQuery({
      [key]: value,
    });
  };

  const fetchNext = () => {
    debug(
      `Fetching ${showing.length} - ${showing.length + pageSize} of ${
        list.length
      } items`
    );
    pushShowing(...list.slice(showing.length, showing.length + pageSize));
  };
  const resetQuery = () => {
    setQuery(initialQuery);
  };
  const value = {
    query,
    isQueried: query.type !== 'latest',
    list,
    showing,
    updateQuery,
    fetchNext,
    hasMore: showing.length < list.length,
    resetQuery,
  };

  return (
    <ResourceQueryContext.Provider value={value}>
      {children}
    </ResourceQueryContext.Provider>
  );
};

export default ResourceQueryProvider;
