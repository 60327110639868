import React from 'react';
import Img from 'gatsby-image';

const imgExtensions = ['gif', 'svg', 'ico'];

const ResourceImage = ({
  className = '',
  image,
  alt,
  objectFit = 'contain',
}) => {
  // No image, no render
  if (image === null || image.extension === '') {
    return null;
  }
  // Render images that cannot be transformed by Sharp
  if (imgExtensions.includes(image.extension)) {
    return (
      <img
        src={image.publicURL}
        className={`resource-image ${className}`}
        alt={alt}
      />
    );
  }
  // Some other invalid type
  if (image.childCloudinaryAsset === null) {
    return null;
  }
  const fluid = image.childCloudinaryAsset.fluid;
  return (
    <Img
      imgStyle={{ objectFit }}
      fluid={{
        ...fluid,
        aspectRatio: fluid.aspectRatio < 3 ? 1.9 : fluid.aspectRatio,
      }}
      className={`resource-image ${className}`}
      alt={alt}
    />
  );
};

export default ResourceImage;
