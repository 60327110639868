import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import useContent from '@hooks/useContent/useContent';

const NoResources = () => {
  const { getContent } = useContent();
  return (
    <div className="mt-4 text-center">
      <Container>
        <Row>
          <Col className="mx-auto text-center">
            <p className="text-dark-gray">{getContent('no-resources')}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NoResources;
