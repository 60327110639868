import React, { useState } from 'react';
import {
  Form,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
} from 'reactstrap';

import { useResourceQuery } from '@providers/ResourceQuery/ResourceQuery';

const ResourceSearchFilter = () => {
  const { query, updateQuery } = useResourceQuery();
  const [search, setSearch] = useState(query.search);

  const handleKeyDown = (ev) => {
    if (ev.keyCode === 27) setSearch('');
  };
  const submitSearch = (ev) => {
    ev.preventDefault();
    updateQuery('search', search);
  };
  const clearSearch = () => {
    setSearch('');
    updateQuery('search', '');
  };
  return (
    <Form onSubmit={submitSearch}>
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>🔎</InputGroupText>
        </InputGroupAddon>
        <Input
          className="text-dark"
          addon
          type="text"
          placeholder="Search for resources"
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText
            className="text-secondary cursor-pointer"
            onClick={clearSearch}
          >
            <span
              className={`transition-in ${
                search !== '' ? 'opacity-100' : 'opacity-0'
              }`}
            >
              ✖
            </span>
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </Form>
  );
};

export default ResourceSearchFilter;
