import React from 'react';
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardImg,
  CardFooter,
  Badge,
} from 'reactstrap';

import ResourceImage from '@components/ResourceImage/ResourceImage';

const ResourceCard = ({ resource }) => {
  return (
    <Col className="mb-4">
      <a
        href={resource.url}
        target="_blank"
        rel="noopener noreferrer"
        title={resource.title}
        className="text-decoration-none"
      >
        <Card className="resource-card shadow-soft border-light animated-grow-1">
          <CardImg
            className="card-img-overlay"
            tag={ResourceImage}
            image={resource.localImage}
            alt={resource.title}
          />
          <CardHeader className="pb-0">
            <h6 className="font-weight-bolder">{resource.title}</h6>
            <hr className="card-separator mb-1" />
          </CardHeader>

          <CardBody className="pt-0">
            <CardText className="mt-0 text-dark">
              {resource.price && <small>{resource.price}</small>}
            </CardText>

            <CardText className="my-2 text-dark">
              {resource.description && <>{resource.description}</>}
            </CardText>
          </CardBody>
          <CardFooter className="text-right">
            {resource.freetrial && (
              <h6 className="type-badge">
                <Badge
                  className="ml-2 mt-2 p-1 text-white font-weight-normal"
                  color="secondary"
                >
                  Free Trial
                </Badge>
              </h6>
            )}
          </CardFooter>
        </Card>
      </a>
    </Col>
  );
};

export default ResourceCard;
